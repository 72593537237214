form.align-form {
    display        : flex;
    justify-content: center;
    padding        : 50px 0px;
    gap            : 50px;
}

.align-card {
    padding: 2rem;
    width  : 50vw;
}

.align-row {
    display        : flex;
    justify-content: space-between;
}

.align-column {
    display       : flex;
    flex-direction: column;
    gap           : 15px;
}

.text-bold {
    font-weight: 700;
}

.duedate-container {
    gap          : 10px;
    display      : flex;
    margin-bottom: 40px;
}

.invoice-number-container {
    display    : flex;
    align-items: center;
}

.number-input {
    display    : flex;
    align-items: center;
}

.textfield-container {
    display      : flex;
    gap          : 88px;
    margin-bottom: 10px;
}

.bill-textfields {
    display       : flex;
    flex-direction: column;
    gap           : 12px;
}

.add-item-container {
    display      : flex;
    gap          : 6em;
    margin-bottom: 10px;
}

.item-container {
    display       : flex;
    flex-direction: column;
    gap           : 12px;
}

.qty-container {
    display    : flex;
    gap        : 25px;
    height     : 35px;
    align-items: center;
}

.add-item-heading {
    display      : flex;
    gap          : 22em;
    margin-bottom: 15px;
}

.qty-heading-text {
    display: flex;
    gap    : 5em;
}

.price-text-heading {
    display: flex;
    gap    : 30px;
}

.rupee-icon {
    display: flex;
}

.price {
    display    : flex;
    align-items: center;
}

.delete-icon {
    background-color: #dc3545;
    border-color    : #dc3545;
    border-radius   : 10px;
    display         : flex;
    align-items     : center;
}

.total-container {
    display        : flex;
    justify-content: flex-end;
}

.margin-gap {
    margin-right: 265px;
}

.span-align-total {
    margin-top: 20px;
}

.total-container-text {
    display       : flex;
    flex-direction: column;
    gap           : 10px;
}

.tag {
    display       : flex;
    flex-direction: column;
    gap           : 10px;
}
.common-btn {
    color           : #fff;
    background-color: #0d6efd;
    border-color    : #0d6efd;
    font-size       : .9rem;
    font-weight     : 500;
    padding         : .66rem;
    border-radius   : 5px;
    margin-bottom   : 10px;
}

.common-btn:hover {
    background-color: #2e66b9;
}
.review-invoice-btn {
    color           : #fff;
    background-color: #0d6efd;
    border-color    : #0d6efd;
    font-size       : .9rem;
    font-weight     : 500;
    padding         : .66rem;
    border-radius   : 5px;
    margin-bottom   : 10px;
    width : 100%;
    height: 50px;
}
.review-invoice-btn:hover {
    background-color: #2e66b9;
}

.sticky-top {
    display       : flex;
    flex-direction: column;
    gap           : 25px;
}

.error-message {
    color    : red;
    font-size: small;
}

/* -----------------Modal css---------------- */
.review-heading {
    display         : flex;
    background-color: #f8f9fa;
    justify-content : space-between;
    padding         : 30px 30px;
}

.text-size {
    color      : gray;
    font-size  : 18px;
    font-weight: 600;
}

.text-bold-size {
    font-size  : 20px;
    font-weight: 600;
}

.modal-content {
    position        : relative;
    display         : flex;
    flex-direction  : column;
    width           : 1000px;
    pointer-events  : auto;
    background-color: #fff;
    background-clip : padding-box;
    border-radius   : .3rem;
    outline         : 0;
}

.bg-light {
    --bs-bg-opacity : 1;
    background-color: rgba(248, 249, 250, var(--bs-bg-opacity)) !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.fw-bold {
    font-weight: 700 !important;
}

.table {
    width         : 100%;
    margin-bottom : 1rem;
    color         : #212529;
    vertical-align: top;
    border-color  : #dee2e6;
}

table {
    caption-side   : bottom;
    border-collapse: collapse;
}

.table>:not(caption)>*>* {
    padding            : .5rem;
    background-color   : var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow         : inset 0 0 0 9999px var(--bs-table-accent-bg);
}

tbody,
td,
tfoot,
th,
thead,
tr {
    border      : 0 solid;
    border-color: inherit;
}

/* ------------------------- */
.name-heading {
    display    : flex;
    align-items: center;
}

.bill-container {
    display        : flex;
    justify-content: space-between;
}

.sub-button-container {
    display        : flex;
    align-items    : flex-end;
    gap            : 10px;
    justify-content: center;
}

.send-invoice-btn {
    color           : #fff;
    background-color: #e563a4;
    padding         : 15px 70px;
    border-radius   : 5px;
    width: 100%;
}

.send-invoice-btn:hover {
    background-color: #e326a4;
}

.download-invoice-btn {
    color           : #fff;
    background-color: #e563a4;
    padding         : 15px 70px;
    border-radius   : 5px;
    width: 100%;
}

.download-invoice-btn:hover {
     background-color: #e326a4;
}

.rate-container {
    display    : flex;
    align-items: center;
}

.tax-container {
    display: flex;
    gap    : 75px;
}

.increment-button-container {
    display       : flex;
    flex-direction: column;
}

.item-heading {
    display: flex;
    gap    : 565px;
}

.heading-contents {
    display: flex;
    gap    : 40px;
}

.item-body {
    display: flex;
}

.item-body-content {
    display: flex;
}

.text-end {
    text-align: right;
}

table.custom-width-table {
    margin-top     : 60px;
    width          : 100%;
    border-collapse: collapse;
    border-top     : 1px solid #ccc;
    border-bottom  : 1px solid #ccc;
}

.notes-review {
    display    : flex;
    height     : 45px;
    align-items: center;
    padding    : 0px 30px;
}

.currency-dropdown {
    width : 300px;
    height: 40px;
}

.overflow-wrap {
    max-width    : 45ch;
    overflow-wrap: anywhere;
}

/* ------------------Responsive------------------ */
@media screen and (max-width: 1360px) {
    form.align-form {
        display        : flex;
        justify-content: center;
        padding        : 10px 10px;
        gap            : 50px;
        flex-direction : column;
    }

    .align-card {
        padding: 1rem;
        width  : 56vw;
    }

    .add-item-container {
        gap: 1em;
    }

    .add-item-heading {
        display      : flex;
        gap          : 13em;
        margin-bottom: 15px;
    }

    .tax-container {
        display        : flex;
        gap            : 75px;
        justify-content: center;
    }

    .qty-heading-text {
        display: flex;
        gap    : 4em;
    }

    .currency-dropdown {
        width : 56vw;
        height: 50px;
    }

    .common-responsive-btn {
        width    : 56vw;
        /* height: 50px; */
    }

    .textfield-container {
        display      : flex;
        gap          : 1em;
        margin-bottom: 10px;
    }

    .qty-container {
        gap: 0em;
    }

    .dialog-container {
        width: 138vw;
    }

    .bill-container {
        display        : flex;
        justify-content: space-between;
        margin-right   : 10em;
    }

    .review-heading {
        display         : flex;
        background-color: #f8f9fa;
        padding         : 30px 30px;
        justify-content : space-between;
        margin-right    : 4em;
    }

    .table {
        width         : 138vw;
        margin-bottom : 1rem;
        color         : #212529;
        vertical-align: top;
        border-color  : #dee2e6;
    }

    .notes-review {
        width: 142vw;
    }
}

@media screen and (max-width: 1024px) {
    form.align-form {
        display        : flex;
        justify-content: center;
        padding        : 10px 10px;
        gap            : 50px;
        flex-direction : column;
    }

    .align-card {
        padding: 1rem;
        width  : 56vw;
    }

    .add-item-container {
        gap: 1em;
    }

    .add-item-heading {
        display      : flex;
        gap          : 13em;
        margin-bottom: 15px;
    }

    .tax-container {
        display        : flex;
        gap            : 75px;
        justify-content: center;
    }

    .qty-heading-text {
        display: flex;
        gap    : 4em;
    }

    .currency-dropdown {
        width : 56vw;
        height: 50px;
    }

    .common-responsive-btn {
        width    : 56vw;
        /* height: 50px; */
    }

    .textfield-container {
        display      : flex;
        gap          : 1em;
        margin-bottom: 10px;
    }

    .qty-container {
        gap: 0em;
    }

    .download-invoice-btn {
        width: 137vw;
    }


    .sub-button-container {
        display       : flex;
        gap           : 10px;
        flex-direction: column;
        align-items   : flex-start;
    }

}

@media screen and (max-width: 820px) {
    form.align-form {
        display        : flex;
        justify-content: center;
        padding        : 10px 10px;
        gap            : 50px;
        flex-direction : column;
    }

    .currency-dropdown {
        width : 750px;
        height: 50px;
    }

    .align-card {
        padding: 1rem;
        width  : 88vw;
    }

    .add-item-container {
        gap: 5em;
    }

    .add-item-heading {
        display      : flex;
        gap          : 20em;
        margin-bottom: 15px;
    }

    .tax-container {
        display        : flex;
        gap            : 75px;
        justify-content: center;
    }

    .qty-heading-text {
        display: flex;
        gap    : 4em;
    }

    .currency-dropdown {
        width : 88vw;
        height: 50px;
    }

    .download-invoice-btn {
        width: 137vw;
    }

  

    .sub-button-container {
        display       : flex;
        gap           : 10px;
        flex-direction: column;
        align-items   : flex-start;
    }
}

@media screen and (max-width: 599px) {
    .invoice-number-container {
        display        : flex;
        align-items    : center;
        justify-content: flex-end;
    }

    .align-row {
        display       : flex;
        flex-direction: column-reverse;
    }

    .align-card {
        width: 125vw;
    }

    .text-bold {
        font-weight: 700;
        font-size  : 14px;
    }

    .textfield-container {
        display: flex;
        gap    : 10px;
    }

    .margin-gap {
        margin-right: 170px;
    }

    .total-container {
        margin-top     : 20px;
        display        : flex;
        justify-content: flex-start;
    }

    .add-item-container {
        gap: 2em;
    }

    .qty-container {
        gap: 0px;
    }

    .add-item-heading {
        gap: 14em;
    }

    .qty-heading-text {
        gap: 46px;
    }

    .price-text-heading {
        gap: 15px;
    }

    .tax-container {
        display        : flex;
        gap            : 75px;
        justify-content: center;
    }

    .modal-content {
        width: 0px;
    }

    .common-responsive-btn {
        width: 122vw;
    }

    .currency-dropdown {
        width : 122vw;
        height: 40px;
    }

    .dialog-container {
        width : 186vw;
        height: 185vh;
    }

    .review-heading {
        display         : flex;
        background-color: #f8f9fa;
        justify-content : space-between;
        padding         : 30px 30px;
        width           : 157vw;
    }

    .bill-container {
        display: flex;
        gap    : 3em;
        width  : 140vw;
    }

    .download-invoice-btn {
        width: 150vw;
    }

  

    .sub-button-container {
        display       : flex;
        gap           : 10px;
        flex-direction: column;
        align-items   : flex-start;
    }

    table.custom-width-table {
        width: 140vw;
    }
}

@media screen and (max-width: 375px) {
    .invoice-number-container {
        display        : flex;
        align-items    : center;
        justify-content: flex-end;
    }

    .align-row {
        display       : flex;
        flex-direction: column-reverse;
    }

    .align-card {
        width: 136vw;
    }

    .text-bold {
        font-weight: 700;
        font-size  : 14px;
    }

    .textfield-container {
        display: flex;
        gap    : 10px;
    }

    .margin-gap {
        margin-right: 170px;
    }

    .total-container {
        margin-top     : 20px;
        display        : flex;
        justify-content: flex-start;
    }

    .add-item-container {
        gap: 2em;
    }

    .qty-container {
        gap: 0px;
    }

    .add-item-heading {
        gap: 13em;
    }

    .qty-heading-text {
        gap: 46px;
    }

    .price-text-heading {
        gap: 15px;
    }

    .tax-container {
        display        : flex;
        gap            : 75px;
        justify-content: center;
    }

    .modal-content {
        width: 0px;
    }

    .bill-container {
        display: flex;
        width  : 145vw;
    }

    .common-responsive-btn {
        width    : 136vw;
        /* height: 50px; */
    }

    .currency-dropdown {
        width : 137vw;
        height: 40px;
    }


    .download-invoice-btn {
        width: 154vw;
    }

  

    .sub-button-container {
        display       : flex;
        gap           : 10px;
        flex-direction: column;
        align-items   : flex-start;
    }

    table.custom-width-table {

        width: 136vw;
    }
}